import React, { useState, FC, useEffect, useRef } from 'react';
import Logo from '../../images/logo.png';
import I18n from '../../helpers/i18n';

import IOSDownload from '../../images/appstore-download.png';
import AndroidDownload from '../../images/googleplay-download.png';

import IconPhone from '../../images/icon-phone.png';
import IconWebsite from '../../images/icon-website.png';
import IconWhatsapp from '../../images/icon-whatsapp.png';
import IconMail from '../../images/icon-mail.png';
import IconContact from '../../images/icon-contact.png';

interface Props {}

const Landingspage: FC<Props> = (props) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="wrapper">
            <header>
                <img src={Logo} className="logo" />
            </header>
            <div className="content-wrapper">
                <div className="section">
                    <h2>{I18n.t('TITLE_DOWNLOAD_APP')}:</h2>

                    <div className="download-ios">
                        <a
                            href={I18n.t('APP_IOS_URL') as string}
                            target="_blank"
                            aria-label="Download iOS App"
                            role="button"
                        >
                            <img src={IOSDownload} alt="Download on the App Store" />
                        </a>
                    </div>
                </div>
                <div className="section-small">
                    <h2>{I18n.t('TITLE_VISIT_WEBSITE')}:</h2>
                    <a
                        href={I18n.t('WEBSITE_URL') as string}
                        className="button -website"
                        target="_blank"
                        aria-label={I18n.t('WEBSITE_TEXT') as string}
                        aria-type="button"
                        role="button"
                    >
                        <img src={IconWebsite} />
                        <span className="button-text">{I18n.t('WEBSITE_TEXT')}</span>
                    </a>
                </div>
                <div className="section">
                    <h2>{I18n.t('TITLE_SUPPORT')}:</h2>
                    <span className="description">{I18n.t('SUBTITLE_SUPPORT')}</span>

                    <span className="subtitle">{I18n.t('PHONE_TEXT')}</span>
                    <a
                        href={'tel:' + I18n.t('PHONE_NR')}
                        className="button -phone"
                        aria-label={('Call ' + I18n.t('PHONE_NR')) as string}
                        role="button"
                    >
                        <img src={IconPhone} />
                        <span className="button-text">{I18n.t('PHONE_NR')}</span>
                    </a>
                    <div className="sub-section">
                        <span className="subtitle">{I18n.t('EMAIL_TEXT')}</span>
                        <a
                            href={'mailto:' + I18n.t('EMAIL')}
                            className="button -email"
                            aria-label={'E-mail ' + I18n.t('EMAIL')}
                            role="button"
                        >
                            <img src={IconMail} />
                            <span className="button-text">{I18n.t('EMAIL')}</span>
                        </a>
                        <span className="alt-text">{I18n.t('EMAIL_TIME')}</span>
                    </div>
                </div>
                <div className="section">
                    <h2>{I18n.t('TITLE_DOWNLOAD_INFO')}:</h2>

                    <a
                        href="assets/vcard.vcf"
                        className="button -contact"
                        download
                        aria-label="Download to Contacts"
                        role="button"
                    >
                        <img src={IconContact} />
                        <span className="button-text">{I18n.t('CARD_TEXT')}</span>
                    </a>
                </div>

                <footer onClick={() => scrollToTop()}>{I18n.t('FOOTER_BACK_TO_TOP')}</footer>
            </div>
        </div>
    );
};

export default Landingspage;
